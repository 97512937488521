import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form, Select, Submit } from 'tui'
import ReusableModal from 'Components/ReusableModal'
import { useBusiness } from 'backend/useBusiness'
import { BeneficiaryTypes } from 'dataSource/options'
import { uiActions } from 'features/uiSlice'
import { businessTypes } from 'dataSource/constants'
import ExpiredBlock from 'Components/ExpiredBlock'

const BeneficiaryModalSelect = ({ business, id }) => {
  const dispatch = useDispatch()
  const [selectedBeneficiaryType, setSelectedBeneficiaryType] = useState('')
  const { updateBusinessDetails } = useBusiness()

  const handleClose = () => {
    dispatch(uiActions.closeReUsableModal())
  }

  const onSubmit = (data) => {
    console.log(data)
    let payload = {
      id: id,
      beneficiaryType: data.beneficiaryType,
      beneficiaryCount: data?.beneficiaryCount || 1,
    }
    if (business.businessType === businessTypes.legacy) {
      payload.beneficiaryType = 'Individual'
    }
    const msg = { loading: 'Updating...', success: 'Beneficiary Type Updated.' }
    updateBusinessDetails(payload, msg).then(() => {
      handleClose()
    })
  }

  const getBeneficiaryTypesOptions = () => {
    if (business?.businessType === businessTypes.legacy) {
      return BeneficiaryTypes.filter((item, i) => item.value === 'Individual')
    }
    return BeneficiaryTypes
  }

  const getBeneficiaryCountOptions = () => {
    let count = 0
    switch (business?.businessType) {
      case businessTypes.trust:
        count = 7
        break
      case businessTypes.legacy:
        count = 5
        break
      case businessTypes.family:
        count = 5
        break
      default:
        count = 2
    }

    return Array(count)
      .fill(1)
      .map((a, i) => ({ value: i + 1, label: String(i + 1) }))
  }

  const getTitle = () => {
    if (business?.blocked) {
      return 'Your business is blocked!'
    } else if (business?.expired) {
      return 'Your business is expired!'
    }
    return 'Select Beneficiary Type'
  }

  return (
    <ReusableModal title={getTitle()}>
      {/* <pre>{JSON.stringify(business, null, 2)}</pre> */}
      {business?.expired || business?.blocked ? (
        <ExpiredBlock business={business} handleClose={handleClose} />
      ) : (
        <Form onFinish={onSubmit} initialValues={business}>
          <div className="grid grid-cols-2 gap-5 mt-8">
            <div className="w-full col-span-2">
              <Select
                required
                name="beneficiaryType"
                label="Beneficiary Type"
                placeholder="Select Type"
                options={getBeneficiaryTypesOptions()}
                rules={{
                  required: 'Beneficiary Type is required',
                }}
                onSelect={(value) => setSelectedBeneficiaryType(value)}
              />
            </div>
            {selectedBeneficiaryType === 'Individual' && (
              <div className="w-full col-span-2">
                <Select
                  required
                  name="beneficiaryCount"
                  label="Number of Beneficiaries"
                  placeholder="Select Number of Beneficiaries"
                  options={getBeneficiaryCountOptions()}
                  rules={{
                    required: 'Number of Beneficiaries is required',
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex justify-end mt-5">
            {/* <button
            type="button"
            className="mr-2 btn btn-secondary"
            onClick={handleClose}
            ref={cancelButtonRef}
          >
            Close
          </button> */}
            <Submit className="btn btn-primary">Submit</Submit>
          </div>
        </Form>
      )}
    </ReusableModal>
  )
}

export default BeneficiaryModalSelect
